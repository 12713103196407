import CarouselV2 from './CarouselV2';
import { useState, useEffect, useContext, useRef } from 'react';
import { ScreenWidthContext } from './App';
import CarouselV3 from './CarouselV3';
import ResetPassword from './ResetPassword';
import heroBannerFull from './heroBanner.jpg';
import heroBanner1000 from './heroBanner_1000.jpg';
import heroBanner500 from './heroBanner_500.jpg';
import { useParams } from 'react-router-dom';

const Home = ({blogs, handleRegisterClick, isLoading, userName:isLoggedIn, topMovies, moviesError, blogsError, registerBtnRef, setActivePanel, registerBtnClicked }) => {

    const screenWidth = useContext(ScreenWidthContext);

    const {passwordResetToken} = useParams();

    const carouselContainer = useRef();

    const [blogsList, setBlogsList] = useState(null);

    const handleRegister = () => {
        handleRegisterClick();
        setActivePanel('register');
    }

    useEffect(() => {
        if (blogs) {;
            setBlogsList(blogs);
        }
    }, [blogs]);

    const [activeIndex, setActiveIndex] = useState(0); //Carousel indexes

    return ( 
        <div className="home" style={{filter: `${registerBtnClicked ? "blur(3px)" : ""}`}}>
            {passwordResetToken && <ResetPassword passwordResetToken={passwordResetToken} />}
            <section className="hero" style={{ backgroundImage: `url(${screenWidth > 1000 ? heroBannerFull : screenWidth > 500 ? heroBanner1000 : heroBanner500}), linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 1) 100%)`}}>
                <h2>Welcome to</h2>
                <h1>Movie Review Blogger</h1>
            </section>
            <section className="triangle"></section>
            <section className="introSection">
                <div className="intro">
                    <p>Movie Review Blogger is a new online application which allows you to review 
                        and rate the most critically-acclaimed movies on a unique blogging journey!
                    </p>
                    <p>Every week, a new movie is randomly chosen from the list of IMDb Top 100 Movies 
                        of All Time for you to watch and rate at your leisure - leave a review in the 
                        blog section, and check out what other people thought about it!</p>
                    <p className={isLoggedIn ? "registerHidden" : "registerVisible"} >Create an account below to get started, or preview the blogs to see what people are
                        making of this week's movie!
                    </p>
                    <button onClick={() => handleRegister()} ref={registerBtnRef} className={isLoggedIn ? "registerHidden" : "register"}>Register</button>
                </div>
                {screenWidth < 1200 && <section style={{width: "100vw", height: "3vh", marginBottom: "40px"}} className="triangle"></section>}
                <div className="homeCarouselContainer" ref={carouselContainer}>
                    <CarouselV3 content={blogsList && blogsList.slice(0, 10)} topMovies={topMovies} container={carouselContainer} isLoading={isLoading} moviesError={moviesError} blogsError={blogsError} type={"blog"} />
                </div>
            </section>
        </div>
     );
}
 
export default Home;