import { useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './NewReview.css';
import StarRating from './StarRating';
import { ScreenWidthContext} from './App';

const NewReview = ({ topMovies, userName, setRegisterBtnClicked, setBlogs, registerBtnClicked, setActivePanel }) => {

    const screenWidth = useContext(ScreenWidthContext);

    const navigate = useNavigate();

    const { id: movieRank } = useParams();

    const [plotRating, setPlotRating] = useState(null);
    const [plotHover, setPlotHover] = useState(null);

    const [castRating, setCastRating] = useState(null);
    const [castHover, setCastHover] = useState(null);

    const [scoreRating, setScoreRating] = useState(null);
    const [scoreHover, setScoreHover] = useState(null);

    const [cinemaRating, setCinemaRating] = useState(null);
    const [cinemaHover, setCinemaHover] = useState(null);

    const [overallRating, setOverallRating] = useState(null);
    const [overallHover, setOverallHover] = useState(null);

    const [isChecked, setIsChecked] = useState(false);

    const [reviewText, setReviewText] = useState("");

    const [aggScore, setAggScore] = useState(null);

    const [buttonText, setButtonText] = useState('Post your review!');

    useEffect(() => {
        setButtonText("Post your review!");
        if (plotRating && castRating && scoreRating && cinemaRating && overallRating) {
            const newAggScore = (plotRating + castRating + scoreRating + cinemaRating ) * 3 + (overallRating * 8);
            setAggScore((newAggScore/10).toFixed(1)); 
        }
    }, [plotRating, castRating, scoreRating, cinemaRating, overallRating]);

    const handlePostReview = (e) => {
        e.preventDefault();

        if (!userName) {
            setRegisterBtnClicked(true);
            setActivePanel("register");
        } else if (!plotRating || !castRating || !scoreRating || !cinemaRating || !overallRating) {
            setButtonText("Missing star ratings!")
        }
        else {

        const reviewBody = {
            email: localStorage.getItem("email"),
            name: localStorage.getItem("name"),
            movie: topMovies[movieRank].title,
            plotRating: plotRating,
            castRating: castRating,
            scoreRating: scoreRating,
            cinemaRating: cinemaRating,
            overallRating: overallRating,
            aggRating: aggScore.toString(),
            reviewText: reviewText == "" ? null : reviewText,
            firstWatch: isChecked,
            date: new Date()
        };

        axios({
            method: 'post',
            url: 'https://api.moviereviewblogger.co.uk/newreview',
            data: reviewBody
        }).then(() => {
            axios.get("https://api.moviereviewblogger.co.uk/reviews").then((response) => {
                setBlogs(response.data.reverse());
            });
        }).then(() => {
            setButtonText("Review posted!")
            setTimeout(() => {
                navigate('/blog');
            }, 2000);
        }).catch((error) => {
            setButtonText("Couldn't submit review :(");
            console.log(error);
        })
        }
    }

    return ( 
        <div className="newReviewWrap" style={{ filter: `${registerBtnClicked ? "blur(3px)" : ""}` }}>
            {topMovies && screenWidth < 964 ?
                <div className="movieReviewPoster mobileResp" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={topMovies[movieRank].image} alt="" />
                    <p className="singleMovieDescription-respons">{topMovies[movieRank].description}</p>
                </div>
            : null}
            {screenWidth < 465 && <h3 className="mobileReviewHeader">Review {topMovies && topMovies[movieRank].title}</h3>}
            <div className="newReviewContainer">
                <div className="reviewBodyWrap">
                    {screenWidth > 464 && <h2 className="reviewHeader">{screenWidth < 500 ? `Review ${topMovies && topMovies[movieRank].title}` : `You are reviewing ${topMovies && topMovies[movieRank].title}`}</h2>}
                    <div className="reviewBody">
                        <form className="reviewForm">
                            <div className="starsCalculatorWrap">
                                <div className="starsWrap">
                                    <div className="plotRatingWrap">
                                        <label className="plotRating">Rate the plot</label>
                                        <StarRating rating={plotRating} setRating={setPlotRating}
                                            hover={plotHover} setHover={setPlotHover} id={topMovies && "newreviewplot" + topMovies[movieRank].title}
                                        />
                                    </div>
                                    <div className="castRatingWrap">
                                        <label className="castRating">Rate the cast</label>
                                        <StarRating rating={castRating} setRating={setCastRating}
                                            hover={castHover} setHover={setCastHover} id={topMovies && "newreviewcast" + topMovies[movieRank].title}
                                        />
                                    </div>
                                    <div className="scoreRatingWrap">
                                        <label className="scoreRating">Rate the score</label>
                                        <StarRating rating={scoreRating} setRating={setScoreRating}
                                            hover={scoreHover} setHover={setScoreHover} id={topMovies && "newreviewscore" + topMovies[movieRank].title}
                                        />
                                    </div>
                                    <div className="cinemaRatingWrap">
                                        <label className="cinemaRating">Rate the cinematography</label>
                                        <StarRating rating={cinemaRating} setRating={setCinemaRating}
                                            hover={cinemaHover} setHover={setCinemaHover} id={topMovies && "newreviewcinema" + topMovies[movieRank].title}
                                        />
                                    </div>
                                    <div className="overallRatingWrap">
                                        <label className="overallRating">Rate the movie overall</label>
                                        <StarRating rating={overallRating} setRating={setOverallRating}
                                            hover={overallHover} setHover={setOverallHover} id={topMovies && "newreviewoverall" + topMovies[movieRank].title}
                                        />
                                    </div>
                                </div>
                                <div className="ratingCalculatorWrap">
                                    <div className="firstTimeWrap">
                                        <label>First time watching?</label>
                                        <input type="checkbox" style={{display: "inline"}} checked={isChecked} onChange={() => setIsChecked(!isChecked)}/>
                                    </div>
                                    <div className="aggregateScoreWrap">
                                        <h2>Your aggregate score is...</h2>
                                        <h2>{aggScore && aggScore}</h2>
                                        {aggScore && <h4>Compared to IMDb rating {topMovies[movieRank].rating}</h4>}
                                        <textarea 
                                        placeholder="What did you think of the movie?" 
                                        className="commentsField"
                                        maxLength="500"
                                        value={reviewText}
                                        onChange={((e) => setReviewText(e.currentTarget.value))}
                                        />
                                        <button 
                                        className="reviewPostBtn"
                                        style={{ color: `${buttonText == "Post your review!" ? "black" : buttonText == "Review posted!" ? "green" : "red" }`}}
                                        onClick={((e) => handlePostReview(e))}
                                        >{buttonText}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {topMovies && (screenWidth > 963) ? <div className="movieReviewPoster">
                    <img src={topMovies[movieRank].image} alt="" />
                    <p className="singleMovieDescription">{topMovies[movieRank].description}</p>
                </div> : null}
            </div>
        </div>
     );
}
 
export default NewReview;