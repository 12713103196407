import { useEffect, useRef, useState, useContext } from 'react';
import './RegisterLogin.css';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import axios from 'axios';
import { ScreenWidthContext } from './App';

const RegisterLogin = ({className, updateLoginPanel, setUserName:setLoggedInName, setEmail, loginBtnRef, registerBtnRef, setActivePanel, activePanel}) => {

    let registerRef = useRef();
    let registerPanelRef = useRef();
    let loginPanelRef = useRef();

    const screenWidth = useContext(ScreenWidthContext);

    const [regError, setRegError] = useState();
    const [loginError, setLoginError] = useState();

    const [successfulRegister, setSuccessfulRegister] = useState();

    useEffect(() => {
        const exitHandler = (event) => {
            if (!registerRef?.current?.contains(event.target) && !loginBtnRef?.current?.contains(event.target) && !registerBtnRef?.current?.contains(event.target)) {
                updateLoginPanel();
            }
        };
        document.addEventListener("mouseup", exitHandler);
        return () => document.removeEventListener("mouseup", exitHandler);
    });

    useEffect(() => {
        const handler = (event) => {
            if (registerPanelRef?.current?.contains(event.target)) {
                setActivePanel("register")
            } else if (loginPanelRef?.current?.contains(event.target)) {
                setActivePanel("login")
            }
        };
        document.addEventListener("mousedown", handler);
        return () => document.removeEventListener("mousedown", handler)
    });

    //Calendar functionality

    const [calendarBtn, setCalendarBtn] = useState(false);

    function handleCalendarClick() {
        setCalendarBtn(calendarBtn => !calendarBtn);
    }

    const [dateValue, dateChange] = useState(null);

    const calendarRef = useRef();

    useEffect(() => {
        const calendarHandler = (event) => {
            if (!calendarRef.current.contains(event.target)) {
                setCalendarBtn(false);
            }
        };
        document.addEventListener("mousedown", calendarHandler);
        return () => document.removeEventListener("mousedown", calendarHandler);
    }, [calendarBtn]);

    const dateFormat = (dateValue) => {
        const string = dateValue.toString()
        return string.slice(4,15);
    }

    //Register form data collection

    const [userName, setUserName] = useState("");
    const [regEmail, setRegEmail] = useState("");
    const [regPass, setRegPass] = useState("");

    const handleRegisterSubmit = (e) => {
        e.preventDefault();

        const regDetails = {
            name: userName,
            dob: dateValue,
            email: regEmail,
            password: regPass
        };

        axios({
            method: 'post',
            url: 'https://api.moviereviewblogger.co.uk/users',
            data: regDetails
        }).then(() => {
            setUserName("");
            setRegEmail("");
            setRegPass("");
            dateChange(null);
            setRegError(null);
            setSuccessfulRegister("Registered successfully!");
        }).catch((error) => {
            if (error.response) {
                if (error.response.data.message == "User validation failed: dob: DoB is required") {
                    setRegError("Please enter your date of birth")
                } else
                setRegError(error.response.data.message);
                console.log(error.response.data);
                console.log(error.response.status);
            } else {
                setRegError('Error:', error.message);
            }
        }).then(() => {
            const loginDetails = {
                email: regEmail,
                password: regPass
            };

            axios({
                method: 'post',
                url: 'https://api.moviereviewblogger.co.uk/login',
                data: loginDetails
            }).then((res) => {
                localStorage.setItem("jwt", res.data.token);
                localStorage.setItem("name", res.data.userName);
                localStorage.setItem("email", res.data.userEmail);
                setLoggedInName(res.data.userName);
                setEmail(res.data.userEmail);
                setLoginEmail("");
                setLoginPassword("");
            }).catch((error) => {
                if (error.response) {
                    setLoginError(error.response.data.message);
                } else {
                    setLoginError('Error:', error.message);
                }
            });
        })
    }

    //Login form data collection

    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");

    const handleLogin = (e) => {
        e.preventDefault();

        const loginDetails = {
            email: loginEmail,
            password: loginPassword
        };

        axios({
            method: 'post',
            url: 'https://api.moviereviewblogger.co.uk/login',
            data: loginDetails
        }).then((res) => {
            localStorage.setItem("jwt", res.data.token);
            localStorage.setItem("name", res.data.userName);
            localStorage.setItem("email", res.data.userEmail);
            setLoggedInName(res.data.userName);
            setEmail(res.data.userEmail);
            setLoginEmail("");
            setLoginPassword("");
        }).catch((error) => {
            if (error.response) {
                setLoginError(error.response.data.message);
            } else {
                setLoginError('Error:', error.message);
            }
        });
    }

    useEffect(() => {
        setRegError(null);
        setLoginError(null);
    }, [userName, regEmail, regPass, dateValue, loginEmail, loginPassword]);

    const [forgottenEmail, setForgottenEmail] = useState();

    const [passwordResetText, setPasswordResetText] = useState('Change password');

    const handlePasswordReset = (e) => {
        e.preventDefault();

        const passwordUpdatePayload = {
            email: forgottenEmail
        }

        axios({
            method: 'post',
            url: 'https://api.moviereviewblogger.co.uk/forgottenpassword',
            data: passwordUpdatePayload
        })
        .then(() => {
            setPasswordResetText("Email link sent")
        })
        .catch((err) => {
            if (err.request) {
                setPasswordResetText(err.response.data.message || "Please try again later");
            } else if (err.response) {
                setPasswordResetText(err.response.data.message);
            }
        })
    }

    useEffect(() => {
        setPasswordResetText('Change password');
    }, [forgottenEmail]);

    return ( 
        <div ref={registerRef} className={className}>
            <div className={activePanel == "register" ? "registerPanel" : screenWidth > 600 ? "registerPanel collapsed" : "registerPanel hidden" } ref={registerPanelRef} style={{ backgroundColor: `${activePanel == "login" ? "rgb(212, 212, 212)" : "white"}` }}>
                <div className="registerPanelBtn" onClick={() => setActivePanel("register")} style={{ backgroundColor: `${activePanel == "register" ? "#f4c404" : "gray"}` }}>Register</div>
                <h3 className="registerPrompt">Register below to begin your movie blogging journey!</h3>
                <form onSubmit={handleRegisterSubmit} className="registerForm">
                    <label>Name:</label>
                    <input className="registerInput"
                        style={regError == "Name has invalid characters" ? { color: "red", fontWeight: "bold" } : {}}
                    type="text"
                    required
                        onChange={(e) => setUserName(e.target.value)}
                    value={userName} />
                    <label style={regError == "Please enter your date of birth" ? { color: "red" } : {}}>Date of Birth:</label>
                    <div className="calendarBtnWrap">
                        <button onClick={handleCalendarClick}>
                            <FontAwesomeIcon icon={faCalendar} size="xl" style={{ color: "#f4c404", }} />
                        </button>
                        <p>{dateValue && dateFormat(dateValue)}</p>
                        <div ref={calendarRef} className={calendarBtn ? "calendarContainer active": "calendarContainer"}>
                            <Calendar onChange={dateChange} value={dateValue} />
                        </div>
                    </div>
                    <label>Email Address:</label>
                    <input className="registerInput"
                        style={regError == "Email address already registered" || regError == "Email address is invalid" ? { color: "red", fontWeight: "bold" } : {}}
                        type="text"
                        required
                        onChange={(e) => setRegEmail(e.target.value)}
                        value={regEmail} />
                    <label>Password:</label>
                    <input className="registerInput"
                        type="password"
                        required
                        onChange={(e) => setRegPass(e.target.value)}
                        value={regPass} />
                    <button id="registerButton" className="regPanelButton">Create your free account!</button>
                    {regError && <p style={{width: "100%", textAlign: "center", color: "red", marginTop: "30px", fontSize: "1.7rem"}}>{regError}</p>}
                </form>
            </div>
            <div className={activePanel == "login" ? "loginPanel" : screenWidth > 600 ? "loginPanel collapsed" : "loginPanel hidden"} ref={loginPanelRef} style={{ backgroundColor: `${activePanel == "register" ? "rgb(212, 212, 212)" : "white"}` }}>
                <div className="loginPanelBtn" onClick={() => setActivePanel("login")} style={{ backgroundColor: `${activePanel == "login" ? "#f4c404" : "gray"}`}}>Log in</div>
                <h3 className="loginPrompt">Already have an account? Login below!</h3>
                <form className="loginForm">
                    <label>Email Address:</label>
                    <input className="registerInput" style={loginError == "User does not exist" ? { color: "red", fontWeight: "bold" } : {}}
                    onChange={(e) => setLoginEmail(e.target.value)}
                    value={loginEmail}
                    type="text"
                    required />
                    <label>Password</label>
                    <input className="registerInput" style={loginError == "Password is incorrect" ? { color: "red", fontWeight: "bold" } : {}}
                    onChange={(e) => setLoginPassword(e.target.value)}
                    value={loginPassword}
                    type="password"
                    required />
                </form>
                <button 
                className="regPanelButton" 
                onClick={(e) => handleLogin(e)}
                style={loginError ? { color: "red", fontWeight: "bold" } : {}}
                >{loginError ? loginError : `Login to your account!`}</button>
                <hr></hr>
                <h3>Forgotten your password? Enter your email address below to change it.</h3>
                <input id="forgotEmailInput" 
                className="registerInput"
                type="text"
                value={forgottenEmail}
                onChange={(e) => setForgottenEmail(e.target.value)} />
                <button 
                className="regPanelButton" 
                onClick={(e) => handlePasswordReset(e)}
                style={{ color: `${passwordResetText == "Email link sent" ? "green" : passwordResetText == "Change password" ? "black" : "red"}`}}>{passwordResetText}</button>
            </div>
        </div>
     );
}
 
export default RegisterLogin;