import Navbar2 from './Navbar2';
import Home from './Home';
import RegisterLogin from './RegisterLogin';
import MovieWeek from './MovieWeek';
import Blog from './Blog';
import NewReview from './NewReview';
import ReadReview from './ReadReview';
import './Home.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import TopMovies from './TopMovies';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

export const TokenContext = React.createContext(null);

export const ScreenWidthContext = React.createContext(window.innerWidth);

function App() {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [token, setToken] = useState(null);

  const [userName, setUserName] = useState(null);

  const [email, setEmail] = useState(null);

  const [topMovies, setTopMovies] = useState(null);

  const [movieOfTheWeek, setMovieOfTheWeek] = useState(null);

  const [blogs, setBlogs] = useState(null);

  const [moviesError, setMoviesError] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const [blogsError, setBlogsError] = useState();

  const [activePanel, setActivePanel] = useState();

  useEffect(() => {
    setUserName(localStorage.getItem("name"));
    setEmail(localStorage.getItem("email"));
  }, []);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Timer for weekly movie reset

  const [resetTime, setResetTime] = useState("...");

  setInterval(() => {
    const currentTime = new Date();

    const daysLeft = 6 - currentTime.getDay();
    const hoursLeft = 23 - currentTime.getHours();
    const minutesLeft = 59 - currentTime.getMinutes();
    const secondsLeft = 59 - currentTime.getSeconds();

    const countdownDisplay = daysLeft + " days, " + hoursLeft + " hours, " + minutesLeft + " minutes, " + secondsLeft + " seconds ";

    setResetTime(countdownDisplay);

    if (daysLeft == 0 && hoursLeft == 0 && minutesLeft == 0 && secondsLeft == 59) {
      axios.get("https://api.moviereviewblogger.co.uk/topmovies").then((response => {
        setMovieOfTheWeek(response.data.rank);
      }))
    }
  }, 1000);

  useEffect(() => {
    axios.get("https://api.moviereviewblogger.co.uk/topmovies")
      .then((response => {
        const sortedMovies = response.data.sort((a,b) => a.rank - b.rank);
        setTopMovies(sortedMovies);
      }))
      .catch((error) => {
        if (error.response) {
          setMoviesError(error.response.data.message);
        } else if (error.request) {
          setMoviesError(error.message)
        }
      });
    axios.get("https://api.moviereviewblogger.co.uk/movieoftheweek")
      .then((response => {
        setMovieOfTheWeek(response.data.rank);
      }))
      .catch((error) => {
        if (error.response) {
          setMoviesError(error.response.data.message);
        } else if (error.request) {
          setMoviesError(error.message)
        }
      })
    axios.get("https://api.moviereviewblogger.co.uk/reviews")
      .then((response) => {
        setBlogs(response.data.reverse());
      })
      .catch((error) => {
        if (error.response) {
          setBlogsError(error.response.data.message);
        } else if (error.request) {
          setBlogsError(error.message)
        }
      })
  }, []);

  useEffect(() => {
    if (blogs, movieOfTheWeek, topMovies) {
      setIsLoading(false);
    }
  }, [blogs, movieOfTheWeek, topMovies])

  useEffect(() => {
    updateLoginPanel();
  }, [userName]);

  //Register button activates entrance animation of register/login panel

  const loginBtnRef = useRef();
  const registerBtnRef = useRef();

  const [registerBtnClicked, setRegisterBtnClicked] = useState(false);

  const handleRegisterClick = () => {
    setRegisterBtnClicked(!registerBtnClicked);
  }

  const updateLoginPanel = () => {
    setRegisterBtnClicked(false);
  }

  return (
    <div className="App">
      <ScreenWidthContext.Provider value={screenWidth}>
        <TokenContext.Provider value={[token, setToken]} >
            <BrowserRouter>
              <Navbar2 handleRegisterClick={handleRegisterClick} userName={userName} setUserName={setUserName} setEmail={setEmail} loginBtnRef={loginBtnRef} setActivePanel={setActivePanel} />
              <main>
                <Routes>
                  <Route path="/" element={<Home blogs={blogs} registerBtnClicked={registerBtnClicked} handleRegisterClick={handleRegisterClick} setActivePanel={setActivePanel} userName={userName} topMovies={topMovies} moviesError={moviesError} blogsError={blogsError} registerBtnRef={registerBtnRef} isLoading={isLoading} />} />
                  <Route path="/forgottenpassword/:passwordResetToken" element={<Home blogs={blogs} registerBtnClicked={registerBtnClicked} handleRegisterClick={handleRegisterClick} setActivePanel={setActivePanel} userName={userName} topMovies={topMovies} moviesError={moviesError} blogsError={blogsError} registerBtnRef={registerBtnRef} isLoading={isLoading} />} />
                  <Route path="movieoftheweek" element={<MovieWeek registerBtnClicked={registerBtnClicked} topMovies={topMovies} resetTime={resetTime} movieOfTheWeek={movieOfTheWeek} moviesError={moviesError} blogsError={blogsError} />} />
                  <Route path="topmovies" element={<TopMovies registerBtnClicked={registerBtnClicked} topMovies={topMovies} email={email} blogs={blogs} moviesError={moviesError} blogsError={blogsError} isLoading={isLoading} />} />
                  <Route path="blog" element={<Blog blogs={blogs} topMovies={topMovies} userName={userName} registerBtnClicked={registerBtnClicked} moviesError={moviesError} blogsError={blogsError} isLoading={isLoading} />}  />
                  <Route path="newreview/:id" element={<NewReview topMovies={topMovies} registerBtnClicked={registerBtnClicked} userName={userName} setRegisterBtnClicked={setRegisterBtnClicked} setBlogs={setBlogs} setActivePanel={setActivePanel} />} />
                  <Route path="readreview/:id" element={<ReadReview blogs={blogs} topMovies={topMovies} registerBtnClicked={registerBtnClicked} />} />
                </Routes>
              </main>
              <RegisterLogin activePanel={activePanel} setActivePanel={setActivePanel} buttonActive={registerBtnClicked} registerBtnRef={registerBtnRef} loginBtnRef={loginBtnRef} updateLoginPanel={updateLoginPanel} setUserName={setUserName} setEmail={setEmail} className={registerBtnClicked ? "registerLoginContainer active" : "registerLoginContainer"} />
            </BrowserRouter>
        </TokenContext.Provider>
      </ScreenWidthContext.Provider>
    </div>
  );
}

export default App;
