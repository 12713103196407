import { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate} from 'react-router-dom';
import './TopMovies.css';
import {ScreenWidthContext} from './App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronDown, faCircleChevronRight, faHourglass2 } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

const TopMovies = ({topMovies, email, blogs, registerBtnClicked, blogsError, moviesError, isLoading}) => {

    const screenWidth = useContext(ScreenWidthContext);

    const navigate = useNavigate();

    const [displayedMovies, setDisplayedMovies] = useState(null); //List of movies that are mapped onto screen, initially set as top 100 list, can be amended by user's choice of filter or sort

    useEffect(() => {
        setDisplayedMovies(topMovies)
    }, [topMovies]);

    const [activeMovies, setActiveMovies] = useState(20); //Number of movies to be displayed on screen, adjusted by user clicking 'Load more'. Reset when user filters or sorts

    const genreContainer = useRef();
    const mobileFiltBanner = useRef();

    const [ genreBoxVisible, setGenreBoxVisible ] = useState(false);

    const handleGenreHover = () => {
        setGenreBoxVisible(true);

        const handler = (event) => {
            if (!genreContainer.current.contains(event.target)) {
                setGenreBoxVisible(false);
                document.removeEventListener("mousemove", handler);
            }
        };
        document.addEventListener("mousemove", handler);
    }

    const handleLoadMore = () => {
        if (activeMovies == 20) {
            const allMovies = document.querySelectorAll('.singleMovie');
            if (displayedMovies.length - 20 >= activeMovies) {
                for (let i = 0; i < activeMovies + 20; i++) {
                    allMovies[i].classList.add('moviesVisible');
                }
                setActiveMovies(60);
            } else {
                let i = 0;
                while (displayedMovies[i]) {
                    allMovies[i].classList.add('moviesVisible');
                    i++;
                };
                setActiveMovies(activeMovies + 20);
            }
        } else if ( activeMovies <= displayedMovies.length ) {
            const allMovies = document.querySelectorAll('.singleMovie');

            if ( displayedMovies.length - 20 >= activeMovies ) {
                for (let i = 0; i < activeMovies; i++) {
                    allMovies[i].classList.add('moviesVisible');
                }
                setActiveMovies(activeMovies + 20);
            } else {
                let i = 0;
                while (displayedMovies[i]) {
                    allMovies[i].classList.add('moviesVisible');
                    i++;
                };
                setActiveMovies(activeMovies + 20);
            }
        }
    }

    const resetActiveMovies = () => {
        if (activeMovies.length > 20) {
            const allMovies = document.querySelectorAll('.singleMovie');
            for (let i = 19; i < displayedMovies.length; i++) {
                allMovies[i].classList.remove('moviesVisible');
            }
        }
    }

    const setActiveFiltButton = (idx) => {
        const filtSettings = document.querySelectorAll('.filtId');
        for (let i = 0; i < filtSettings.length; i++) {
            if (i == idx) {
                filtSettings[idx].classList.add('sortFiltActive');
            } else {
                filtSettings[i].classList.remove('sortFiltActive');
            }
        }
        
    }

    const [genre, setGenre] = useState("Genre");

    //Sort for genre function

    const sortMovies = (genre, e) => {
        if (isLoading) return;
        const filteredMovies = topMovies.filter(x => x.genre.includes(genre));
        setNoReviews(false);
        setDisplayedMovies(filteredMovies);
        setActiveMovies(20);
        resetActiveMovies();
        setActiveFiltButton(1);
        setGenre(e.target.innerText);
        setGenreBoxVisible(false);
        setMobileSortOption(genre);
        setMobDropDownActive(false);
    }

    //Sort for release date function

    const sortMoviesReleaseDate = () => {
        if (isLoading) return;
        const releaseDateSorted = topMovies.toSorted((a, b) => a.year - b.year);
        setNoReviews(false);
        setDisplayedMovies(releaseDateSorted);
        setActiveMovies(20);
        resetActiveMovies();
        setActiveFiltButton(2);
        setGenre("Genre");
        setMobileSortOption("Release date");
        setMobDropDownActive(false);
    }

    //Sort for IMDb rating (default)

    const sortIMDbOrder = () => {
        if (isLoading) return;
        setNoReviews(false);
        setDisplayedMovies(topMovies);
        setActiveMovies(20);
        resetActiveMovies();
        setActiveFiltButton(0);
        setGenre("Genre");
        setMobileSortOption("IMDb Rating");
        setMobDropDownActive(false);
    }

    //Sort for user's rating

    const [noReviews, setNoReviews] = useState(false);

    const sortUserOrder = () => {
        if (isLoading) return;
        const userReviews = blogs.filter((x) => x.email == email);
        if (userReviews.length == 0) setNoReviews(true);
        let reviewedMovies = [];
        userReviews.forEach((review) => {
            const movie = topMovies.filter((x) => x.title == review.movie);
            movie[0].aggRating = review.aggRating;
            reviewedMovies = [movie, ...reviewedMovies];
        });
        const sortedReviewedMovies = reviewedMovies.flat().sort((a, b) => b.aggRating - a.aggRating);
        setDisplayedMovies(sortedReviewedMovies);
        setActiveMovies(20);
        resetActiveMovies();
        setActiveFiltButton(3);
        setGenre("Genre");
        setMobileSortOption("Your Rating");
        setMobDropDownActive(false);
    }

    //Click on movie to submit a review

    const handleMovieClick = (rank) => {
        navigate('/newreview/' + (rank -1));
    }

    //Filter toolbars

    const renderGenres = () => {
        return (
        <>
            <div onClick={((e) => sortMovies("Action", e))}>Action</div>
            <div onClick={((e) => sortMovies("Crime", e))}>Crime</div>
            <div onClick={((e) => sortMovies("Drama", e))}>Drama</div>
            <div onClick={((e) => sortMovies("Romance", e))}>Romance</div>
            <div onClick={((e) => sortMovies("Biography", e))}>Biography</div>
            <div onClick={((e) => sortMovies("History", e))}>History</div>
            <div onClick={((e) => sortMovies("Western", e))}>Western</div>
            <div onClick={((e) => sortMovies("Family", e))}>Family</div>
            <div onClick={((e) => sortMovies("Fantasy", e))}>Fantasy</div>
            <div onClick={((e) => sortMovies("Mystery", e))}>Mystery</div>
            <div onClick={((e) => sortMovies("Thriller", e))}>Thriller</div>
            <div onClick={((e) => sortMovies("War", e))}>War</div>
            <div onClick={((e) => sortMovies("Comedy", e))}>Comedy</div>
            <div onClick={((e) => sortMovies("Animation", e))}>Animation</div>
            <div onClick={((e) => sortMovies("Musical", e))}>Musical</div>
        </>
        )
    }

    const mobSortRef = useRef();

    useEffect(() => {

        const closeAllMenu = (event) => {
            if (!mobSortRef?.current?.contains(event.target) && !mobileFiltBanner?.current?.contains(event.target)) {
                setMobileGenreListActive(false);
                setMobDropDownActive(false);
            }
        }

        document.addEventListener("mousedown", closeAllMenu);

        return () => document.removeEventListener("mousedown", closeAllMenu);
    })

    const desktopFiltToolbar = () => {
        return <div className="filterBanner">
            <div>
                <div>Sort movies by:</div>
            </div>
            <div>
                <div><button onClick={() => sortIMDbOrder()} className="filtId sortFiltActive">IMDb Rating</button></div>
                <div ref={genreContainer} className="genreContainer">
                    <div><button onMouseEnter={() => handleGenreHover()} className="filtId">{genre}</button></div>
                    <div className={genreBoxVisible ? "genreDropdownActive" : "genreDropdownInactive"}>
                        {genreBoxVisible && renderGenres()}
                    </div>
                </div>
                <div><button onClick={() => sortMoviesReleaseDate()} className="filtId">Release date</button></div>
                <div><button onClick={() => sortUserOrder()} className="filtId">Your Rating</button></div>
            </div>
        </div>
    }

    const [mobDropDownActive, setMobDropDownActive] = useState(false);
    const [mobileGenreListActive, setMobileGenreListActive] = useState(false);
    const [mobileSortOption, setMobileSortOption] = useState("IMDb Rating");

    const mobileFiltToolbar = () => {
        return <div className="mobFiltBanner" ref={mobileFiltBanner}>
                    <div className="mobSortBtn">
                        Sort movies by:
                    </div>
                    <div className="mobSortDropdown">
                        <div className="activeMobSort">
                            <div>{mobileSortOption}</div>
                        <FontAwesomeIcon icon={faCircleChevronDown} style={{height: "30px", padding: "10px", color: "#000000" }} onClick={() => setMobDropDownActive(!mobDropDownActive)} />
                        </div>
                        <div className={mobDropDownActive ? "mobSortOptions mobSortOptionsVisible" : "mobSortOptions"} ref={mobSortRef}>
                            <button onClick={() => sortIMDbOrder()}>IMDb Rating</button>
                            <button onClick={() => sortMoviesReleaseDate()}>Release date</button>
                            <button onClick={() => sortUserOrder()}>Your Rating</button>
                            <div className="mobGenreBtn">
                                <button onClick={() => setMobileGenreListActive(!mobileGenreListActive)}>Genre <FontAwesomeIcon icon={faCircleChevronRight} /></button>
                                <div className={mobileGenreListActive ? "mobGenreList list-active" : "mobGenreList"}>
                                    {mobileGenreListActive && renderGenres()}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
    }

    const renderMovies = () => {
        return displayedMovies.map((movie, id) => (
            <motion.div className="singleMovie"
                onClick={() => handleMovieClick(movie.rank)}
                key={id}>
                <div className="moviePosterContainer">
                    <img src={movie.image} alt="" />
                    <p className="singleMovieDescription">{movie.description}</p>
                </div>
                <div className="rank">{movie.rank}</div>
                <div className="titleReleaseContainer">
                    <h3 className="movieTitle">{movie.title}</h3>
                    <p className="movieDate">{movie.year}</p>
                </div>
            </motion.div>
        ))
    }

    const loadingMovies = () => {
        return [...Array(20)].map((slide, id) => {
            return <motion.div className="singleMovie-loading"
                key={"loadingMovie" + id}>
                    <h3 className="movieTitle">Loading movies...</h3>
            </motion.div>
        })
    }

    return ( 
        <div className="moviesPageWrapper" style={{ filter: `${registerBtnClicked ? "blur(3px)" : ""}` }}>
            {screenWidth > 750 ? desktopFiltToolbar() : mobileFiltToolbar()}
            {noReviews && <h2 style={{marginTop: "30px"}}>You haven't reviewed any movies yet!</h2>}
            <motion.div className="topMoviesContainer">
                {isLoading ? loadingMovies() : displayedMovies && renderMovies()}
            </motion.div>
            {!isLoading && displayedMovies && activeMovies <= displayedMovies.length ? <button className="loadMoreButton" onClick={handleLoadMore}>Next 20 Movies</button> : <div></div>}
        </div>
     );
}
 
export default TopMovies;