import { useRef, useState, useEffect, useContext } from 'react';
import './CarouselV3.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { faCircleRight } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { ScreenWidthContext } from './App';
import { useNavigate } from 'react-router-dom';

const CarouselV3 = ({ content, topMovies, container, movieCarouselIndex, isLoading, moviesError, blogsError, type, movieOfTheWeek }) => {

    const navigate = useNavigate();

    const screenWidth = useContext(ScreenWidthContext);

    const slideRef = useRef();

    let slideWidth = (container?.current?.getBoundingClientRect().width / 3);

    const [containerWidth, setContainerWidth] = useState(slideWidth);

    const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);

    useEffect(() => {
        setContainerWidth(slideWidth);
    }, [container]);

    const handleNextSlide = () => {
        if (carouselActiveIndex == (content.length - 1)) {
            setCarouselActiveIndex(0);
        } else setCarouselActiveIndex((prev) => prev + 1);
    }

    const handlePrevSlide = () => {
        if (carouselActiveIndex == 0) {
            setCarouselActiveIndex(content.length - 1);
        } else setCarouselActiveIndex((prev) => prev - 1);
    }

    const handleReadBlog = (id) => {
        navigate('/readreview/' + (id));
    }

    //Mobile scrolling functionality

    const carouselTrackRef = useRef();

    let initialX;

    const swipeCarouselStart = (e) => {
        if (e.target.closest(".track_containerV3")) {
            initialX = e.targetTouches[0].clientX;
        }
    }

    // const swipeCarouselMove = (e) => {
    //     let movedX = e.changedTouches[0].clientX;

    //     let differenceX = movedX - initialX;

    //     if (differenceX > 50 || differenceX < -50) {
    //         return
    //     }

    //     else if (-50 < differenceX < 50) {
            
    //     }
    // }

    const swipeCarouselEnd = (e) => {
        let endX = e.changedTouches[0].clientX;

        if (endX - initialX < -50) {
            if (carouselActiveIndex == (content.length - 1)) {setCarouselActiveIndex(0)}
            else {setCarouselActiveIndex((prev) => prev + 1)}
        }
        else if (endX - initialX > 50) {
            if (carouselActiveIndex == 0) {setCarouselActiveIndex(9)}
            else {setCarouselActiveIndex((prev) => prev - 1)}
        }
    }

    useEffect(() => {
        if (content) {
            document.addEventListener("touchstart", swipeCarouselStart);
            //document.addEventListener("touchmove", swipeCarouselMove);
            document.addEventListener("touchend", swipeCarouselEnd);

            return () => {
                document.removeEventListener("touchstart", swipeCarouselStart);
                document.removeEventListener("touchend", swipeCarouselEnd);
                //document.removeEventListener("touchmove", swipeCarouselMove);
            }
        }
    }, [content]);

    const renderBlogs = () => {
            return content.map((blog, index) => {
                return <motion.div className={index == carouselActiveIndex ? "blogSlide-active blogSlide" : "blogSlide"} key={blog._id}
                    onClick={index == carouselActiveIndex ? () => handleReadBlog(blog._id) : null}
                    ref={slideRef}
                    style={{
                        width: `${container?.current.getBoundingClientRect().width / (screenWidth < 880 ? (screenWidth < 530 ? 1.5 : 2.4) : 3)}px`,
                        transform: `rotateY(${index == carouselActiveIndex ? 0 : index > carouselActiveIndex ? -45 : 45}deg)`,
                        zIndex: `${index == carouselActiveIndex ? "999" : `${index == carouselActiveIndex + 1 ? "998" : (index == carouselActiveIndex - 1) ? "998" : "1"}`}`
                    }}
                    initial={{
                        left: `${index == carouselActiveIndex ? (container?.current.getBoundingClientRect().width / (screenWidth < 880 ? (screenWidth < 530 ? 6 : 3.4) : 3)) : ((index + (screenWidth < 530 ? .5 : 1) - carouselActiveIndex) * (container?.current.getBoundingClientRect().width / (screenWidth < 880 ? (screenWidth < 530 ? 3 : 3.4) : 3)))}px`,
                        transform: 'rotateY(0deg)'
                    }}
                    animate={{
                        left: `${index == carouselActiveIndex ? (container?.current.getBoundingClientRect().width / (screenWidth < 880 ? (screenWidth < 530 ? 6 : 3.4) : 3)) : ((index + (screenWidth < 530 ? .5 : 1) - carouselActiveIndex) * (container?.current.getBoundingClientRect().width / (screenWidth < 880 ? (screenWidth < 530 ? 3 : 3.4) : 3)))}px`,
                        transform: `rotateY(${index == carouselActiveIndex ? 0 : index > carouselActiveIndex ? -45 : 45}deg)`
                    }}
                    transition={{ type: 'spring', stiffness: 50 }}
                >
                    <h3>Reviewed by {blog.name.length > 10 ? (blog.name.slice(0,9) + "...") : blog.name} on {blog.date.slice(0, 10)}</h3>
                    <div>
                        <img src={topMovies && topMovies.find((movie) => movie.title == blog.movie).image} alt="" />
                        {screenWidth > 724 && <p>Click for more...</p>}
                    </div>
                    <p>{blog.aggRating}</p>
                </motion.div>
            })
    }

    const renderMovies = () => {
        return content && content.map((movie, index) => {
            return <motion.div className={index == movieCarouselIndex ? "blogSlide-active featuredMovieSlide blogSlide" : "blogSlide"} key={movie.title}
                ref={slideRef}
                style={{
                    width: `${container.current ? container.current.getBoundingClientRect().width / 3 : 0}px`,
                    transform: `rotateY(${index == movieCarouselIndex ? 0 : index > movieCarouselIndex ? -45 : 45}deg)`
                }}
                initial={{
                    left: `${(index + 1 - movieCarouselIndex) * (container.current ? container.current.getBoundingClientRect().width / 3 : 0)}px`,
                    transform: 'rotateY(0deg)'
                }}
                animate={{
                    left: `${(index + 1 - movieCarouselIndex) * (container.current ? container.current.getBoundingClientRect().width / 3 : 0)}px`,
                    transform: `rotateY(${index == movieCarouselIndex ? 0 : index > movieCarouselIndex ? -45 : 45}deg)`
                }}
                transition={{ type: 'tween', duration: movieOfTheWeek > 50 ? 3 : movieOfTheWeek < 25 ? 1 : 2 }}
            >
                {screenWidth > 635 && <h3 style={{ color: "white", fontSize: "1.5rem" }}>{`${movie.title.length > 20 ? movie.title.slice(0, 19) + "..." : movie.title}`}</h3>}
                <div><img src={movie.image} /></div>
            </motion.div>
        })
    }

    const renderWaiting = () => {
        return [...Array(3)].map((slide, index) => {
            return <div className={index == 0 ? "blogSlide-active blogSlide-loading featuredMovieSlide blogSlide" : "blogSlide blogSlide-loading"} key={`loadingBlog${index}`}
                ref={slideRef}
                style={{
                    width: `${container.current ? container.current.getBoundingClientRect().width / 3 : 0}px`,
                    left: `${(index + 1) * (container.current ? container.current.getBoundingClientRect().width / 3 : 0)}px`,
                    transform: `rotateY(${index == 0 ? 0 : index > 0 ? -45 : 45}deg)`,
                    borderRadius: "5px"
                }}>
                {type == "blog" && <h3 style={{ width: "95%", height: "10%", margin: "0 auto", marginTop: "2%", borderRadius: "5px" }}></h3>}
                <div style={{ width: "95%", height: `${type == "blog" ? "85%" : "97%"}`, margin: "0 auto", marginTop: "2%", marginBottom: "1%", borderRadius: "5px", position: "relative" }}>
                    <img style={{ width: "100%", height: "100%", padding: "0", borderRadius: "5px" }}/>
                    <p className="loadingP" 
                    style={{ position: "absolute", fontSize: "1.7rem", top: "50%", width: "95%", margin: "0 2.5%", transform: "translateY(-50%)", textAlign: "center", color: "white" }}>{(blogsError || moviesError) ? "Server error - try again later" : type == "blog" ? "Loading blogs..." : "Loading movies..."}</p>
                </div>
            </div>
        })
    }

    return ( 
        <div className="carouselV3">
            <div className="track_containerV3">
                <div className="carouselV3_track" ref={carouselTrackRef}>
                    {(isLoading || moviesError || blogsError) ? renderWaiting() : content && content[0].aggRating ? renderBlogs() : renderMovies()}
                </div>
                {(!blogsError && !moviesError) && content && content[0].aggRating ? <div className="carouselV3_nav">
                    <button className="carousel_buttonV3 carousel_button--leftV3"
                        onClick={() => handlePrevSlide()}>
                        <FontAwesomeIcon icon={faCircleLeft} size="xl" style={{ color: "white" }} />
                    </button>
                    {content && content.map((slide, index) => {
                        return <button
                            onClick={() => setCarouselActiveIndex(index)}
                            key={index}
                            className={index == carouselActiveIndex ? "carouselNavBtn-active" : "carouselNavBtn"}>
                        </button>
                    })}
                    <button className="carousel_buttonV3 carousel_button--rightV3"
                        onClick={() => handleNextSlide()}>
                        <FontAwesomeIcon icon={faCircleRight} size="xl" style={{ color: "white" }} />
                    </button>
                </div> : null }
            </div>
        </div>
     );
}
 
export default CarouselV3;