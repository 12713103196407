import './Blog.css';
import { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersLine, faClapperboard, faScroll, faMusic, faRankingStar } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { ScreenWidthContext } from './App';
import blogBannerFull from './movieblogbanner.jpg';
import blogBanner952 from './movieblogbanner_952.jpg';
import blogBanner631 from './movieblogbanner_631.jpg';

const Blog = ({blogs, topMovies, registerBtnClicked, isLoading}) => {

    const screenWidth = useContext(ScreenWidthContext);

    const navigate = useNavigate();

    const [activeReview, setActiveReview] = useState(null);

    const displayReviewText = (blog) => {
        return (
            <div style={activeReview == blog._id ? { opacity: "1", transform: "translateY(-50%)" } : {}} className="reviewText">{blog.reviewText?.slice(0, 300) + `${blog.reviewText[299] ? "..." : ""}`}</div>
        )
    }

    const handleReadBlog = (id) => {
        navigate('/readreview/' + (id));
    }

    const renderReviews = () => {
        return blogs.map((blog) => (
            <div className="singleReview" onClick={() => handleReadBlog(blog._id)} key={"displayblog" + blog._id}>
                <h3 className="reviewTitle">Reviewed by {blog.name} on {blog.date.slice(0, 10)}</h3>
                <div className="contentWrap">
                    <div className="imgTextWrap" onMouseOver={() => setActiveReview(blog._id)} onMouseLeave={() => setActiveReview(null)}>
                        {blog.reviewText ? displayReviewText(blog) : <div></div>}
                        <img style={blog.reviewText && activeReview == blog._id ? { filter: "brightness(0.3)" } : {}} src={topMovies.find((movie) => movie.title == blog.movie).image} alt="" />
                    </div>
                    <div className="ratingPanel">
                        <div style={{ position: "relative" }}><span>Plot:</span><FontAwesomeIcon icon={faScroll} style={{ display: "inline", color: "white", paddingLeft: "5px" }} size="xl" /><p>{blog.plotRating}</p></div>
                        <div style={{ position: "relative" }}><span>Cast:</span><FontAwesomeIcon icon={faUsersLine} style={{ display: "inline", color: "white", paddingLeft: "5px" }} size="xl" /><p>{blog.castRating}</p></div>
                        <div style={{ position: "relative" }}><span>Score:</span><FontAwesomeIcon icon={faMusic} style={{ display: "inline", color: "white", paddingLeft: "5px" }} size="xl" /><p>{blog.scoreRating}</p></div>
                        <div style={{ position: "relative" }}><span style={{ transform: "translateX(-100%) translateY(0)" }}>Cinema-<br />tography:</span><FontAwesomeIcon icon={faClapperboard} style={{ display: "inline", color: "white", paddingLeft: "5px" }} size="xl" /><p>{blog.cinemaRating}</p></div>
                        <div style={{ position: "relative" }}><span>Overall:</span><FontAwesomeIcon icon={faRankingStar} style={{ display: "inline", color: "white", paddingLeft: "5px" }} size="xl" /><p>{blog.overallRating}</p></div>
                        <div className="reviewAggRating">{blog.aggRating}</div>
                    </div>
                </div>
            </div>
        ))
    }

    const loadingMovies = () => {
        return [...Array(6)].map((slide, id) => {
            return <div key={"review" + id} className="blog-loading">
                <h3>Loading reviews...</h3>
            </div>
        })
    }

    return ( 
        <div className="blogPageContainer" style={{ filter: `${registerBtnClicked ? "blur(3px)" : ""}` }}>
            <section className="blogPageHero" style={{ backgroundImage: `url(${screenWidth > 952 ? blogBannerFull : screenWidth > 631 ? blogBanner952 : blogBanner631}), linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 1) 50%)`}}>
                <h1>Blogs and Reviews</h1>
            </section>
            <section className="triangle"></section>
            <section className="blogReviewsContainer">
                {isLoading ? loadingMovies() : blogs && topMovies && renderReviews()}
            </section>
        </div>
     );
}
 
export default Blog;