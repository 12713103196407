import { useNavigate } from 'react-router-dom';
import './ResetPassword.css';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';

const ResetPassword = ({passwordResetToken}) => {

    const passwordResetWindow = useRef();

    const navigate = useNavigate();

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [verifyPassword, setVerifyPassword] = useState();
    const [error, setError] = useState(null);
    const [buttonText, setButtonText] = useState('Change your password');

    useEffect(() => {
        const closeWindow = (event) => {
            if (!passwordResetWindow?.current?.contains(event.target)) {
                navigate('/');
            }
        }

        document.addEventListener('click', closeWindow);

        return () => document.removeEventListener('click', closeWindow);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (password == verifyPassword) {

            setError(null);

            const payload = {
                token: passwordResetToken,
                email: email,
                newPassword: password
            };

            axios({
                method: 'patch',
                url: 'https://api.moviereviewblogger.co.uk/forgottenpassword',
                data: payload
            })
            .then(() => {
                setError("Password changed successfully");
                    // setError(res.response.data.message || "Could not change password")
            })
            .catch((error) => {
                if (error.response) {
                    setError(error.response.data.message)
                } else if (error.request) {
                    setError("Could not reset password");
                }
            })
        } else setError('Passwords must match!')
    }

    useEffect(() => {
        if (error) {
            setButtonText(error);
        }
    }, [error]);

    useEffect(() => {
        setError(null);
        setButtonText('Change your password');
    }, [email, password, verifyPassword]);

    return ( 
        <div className="resetPasswordContainer" ref={passwordResetWindow}>
            <h2>Reset your password</h2>
            <form onSubmit={handleSubmit}>
                <label>Verify your email address</label>
                <input type="text"
                required
                onChange={(e) => setEmail(e.target.value)}
                 />
                <hr></hr>
                <label>Enter your new password</label>
                <input type="password"
                required
                onChange={(e) => setPassword(e.target.value)} />
                <hr></hr>
                <label>Verify your new password</label>
                <input type="password"
                required
                onChange={(e) => setVerifyPassword(e.target.value)} />
                <button style={{color: `${error ? error == "Password changed successfully" ? 'green' : 'red' : 'black'}`}}>{buttonText}</button>
            </form>
        </div>
     );
}
 
export default ResetPassword;