import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import StarRating from "./StarRating";
import { ScreenWidthContext } from './App';

const ReadReview = ({blogs, topMovies, registerBtnClicked}) => {

    const screenWidth = useContext(ScreenWidthContext);

    const { id: blogId} = useParams();

    const [blog, setBlog] = useState();

    const [movieRating, setMovieRating] = useState();

    useEffect(() => {
        blogs && setBlog(blogs.filter((x) => x._id == blogId));
    }, [blogs, blogId]);

    useEffect(() => {
        blog && topMovies && setMovieRating(topMovies.filter((x) => x.title == blog[0].movie)[0].rating);
    }, [blog, topMovies]);

    const renderContent = () => {
        return <div className="newReviewContainer" style={{ filter: `${registerBtnClicked ? "blur(3px)" : ""}` }}>
            <div className="reviewBodyWrap">
                {screenWidth > 464 && <h2 className="reviewHeader">Reviewed by {blog[0].name} on {blog[0].date.slice(0, 10)}</h2>}
                <div className="reviewBody">
                    <form className="reviewForm">
                        <div className="starsCalculatorWrap">
                            <div className="starsWrap">
                                <div className="plotRatingWrap">
                                    <label className="plotRating">Plot rating</label>
                                    <StarRating rating={blog[0].plotRating} id={"plot" + blog[0]._id} readOnly={true}
                                        />
                                </div>
                                <div className="castRatingWrap">
                                    <label className="castRating">Cast rating</label>
                                    <StarRating rating={blog[0].castRating} id={"cast" + blog[0]._id} readOnly={true}
                                        />
                                </div>
                                <div className="scoreRatingWrap">
                                    <label className="scoreRating">Score rating</label>
                                    <StarRating rating={blog[0].scoreRating} id={"score" + blog[0]._id} readOnly={true}
                                        />
                                </div>
                                <div className="cinemaRatingWrap">
                                    <label className="cinemaRating">Cinematography rating</label>
                                    <StarRating rating={blog[0].cinemaRating} id={"cinema" + blog[0]._id} readOnly={true}
                                        />
                                </div>
                                <div className="overallRatingWrap">
                                    <label className="overallRating">Overall rating</label>
                                    <StarRating rating={blog[0].overallRating} id={"overall" + blog[0]._id} readOnly={true}
                                        />
                                </div>
                            </div>
                            <div className="ratingCalculatorWrap">
                                <div className="firstTimeWrap">
                                    <label>First time watching?</label>
                                    <input type="checkbox" style={{ display: "inline" }} checked={blog[0].firstWatch} readOnly/>
                                </div>
                                <div className="aggregateScoreWrap">
                                    <h2>{blog[0].name}'s aggregate score is...</h2>
                                    <h2>{blog[0].aggRating}</h2>
                                    <h4>Compared to IMDb rating { movieRating }</h4>
                                    <textarea
                                        placeholder={`${blog[0].name} didn't leave any comments about this film.`}
                                        className="commentsField"
                                        maxLength="500"
                                        value={blog[0].reviewText || `${blog[0].name} didn't leave any comments about this film.`}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {screenWidth < 964 ? null : <div className="movieReviewPoster">
                <img src={topMovies.filter((x) => x.title == blog[0].movie)[0].image} alt="" />
                <p className="singleMovieDescription">{topMovies.filter((x) => x.title == blog[0].movie)[0].description}</p>
            </div>}
        </div>
    }

    return (
        <div className="newReviewWrap">
            {topMovies && blog && screenWidth < 964 ? 
                <div className="movieReviewPoster mobileResp" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <img src={topMovies.filter((x) => x.title == blog[0].movie)[0].image} alt="" />
                    <p className="singleMovieDescription-respons">{topMovies.filter((x) => x.title == blog[0].movie)[0].description}</p>
                </div> 
            : null}
            {screenWidth < 465 && blog && <h3 className="mobileReviewHeader">Reviewed by {blog[0].name} on {blog[0].date.slice(0, 10)}</h3>}
            {blog && topMovies && renderContent()}
        </div>
     );
}
 
export default ReadReview;