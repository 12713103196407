import "./MovieWeek.css";
import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ScreenWidthContext } from './App';
import CarouselV3 from './CarouselV3';
import { useInView } from "framer-motion";
import movieBannerFull from './movieweekbanner.jpg';
import movieBanner1088 from './movieweekbanner_1088.jpg';
import movieBanner460 from './movieweekbanner_460.jpg';

const MovieWeek = ({topMovies, movieOfTheWeek, moviesError, blogsError, registerBtnClicked}) => {

    const navigate = useNavigate();

    const screenWidth = useContext(ScreenWidthContext);

    const [featuredMovie, setFeaturedMovie] = useState(null);

    const [activeIndex, setActiveIndex] = useState(0); //Carousel indexes

    const handleReview = () => {
        navigate('/newreview/' + (featuredMovie.rank - 1));
    }

    useEffect(() => {
        if (topMovies && movieOfTheWeek) {
            setFeaturedMovie(topMovies[movieOfTheWeek -1]);
        }
    }, [topMovies,movieOfTheWeek]);

    const movieSectionRef = useRef();
    const featuredSectionRef = useRef();

    const movieIsIntersecting = useInView(featuredSectionRef, {
        margin: `${screenWidth < 800 ? "-150px" : "-250px"}`,
        once: true
    });

    useEffect(() => {
        const enterCarousel = () => {
            setActiveIndex(movieOfTheWeek - 1);
        }
        if (movieIsIntersecting) {
        setTimeout(enterCarousel, 1000);
        }
    }, [movieIsIntersecting, movieOfTheWeek]);

    return ( 
        <div className="movieWeekContainer" style={{ filter: `${registerBtnClicked ? "blur(3px)" : ""}` }}>
            <div className="movieWeekBanner" style={{ backgroundImage: `url(${screenWidth > 1088 ? movieBannerFull : screenWidth > 460 ? movieBanner1088 : movieBanner460}), linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 1) 100%)`}}>
                <h2>This Week's Movie...</h2>
            </div>
            <section className="triangle"></section>
            <section className="featuredFilm" ref={featuredSectionRef}>
                <div className={movieIsIntersecting ? "filmCarouselContainer inView" : "filmCarouselContainer"} ref={movieSectionRef} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <CarouselV3 content={topMovies && topMovies} container={movieSectionRef} movieCarouselIndex={activeIndex} type={"movies"} movieOfTheWeek={movieOfTheWeek} moviesError={moviesError} blogsError={blogsError} />
                    <button onClick={() => handleReview()}>Review this film!</button>
                </div>
                {featuredMovie && <div className="featuredFilmText">
                    <p className={movieIsIntersecting ? "movieSectionVisible": "movieInfo"}><span>{featuredMovie.title}</span> is this week's randomly-chosen movie!</p>
                    <p id="movieDescription" className={movieIsIntersecting ? "movieSectionVisible" : "movieInfo"}>"{featuredMovie.description}"</p>
                    <p className={movieIsIntersecting ? "movieSectionVisible" : "movieInfo"}>If you're a fan of <span>{featuredMovie.genre.join(", ")}</span> films, you're likely to enjoy this one!</p>
                    <p className={movieIsIntersecting ? "movieSectionVisible" : "movieInfo"}>Released in <span>{featuredMovie.year}</span>, this movie has gained a critical rating of <span>{featuredMovie.rating}</span> - how will your rating compare?</p>
                </div>}
            </section>
        </div>
     );
}
 
export default MovieWeek;