import IMDbLogo from './IMDb-Logo.png';
import { Link, NavLink } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import './Navbar2.css';

const Navbar2 = ({handleRegisterClick, userName: loggedInUserName, setUserName, setEmail, loginBtnRef, setActivePanel}) => {

    const navigate = useNavigate();

    const [jwtName, setJwtName] = useState(null);
    const [hamMenuActive, setHamMenuActive] = useState(false);

    useEffect(() => {
        setJwtName(localStorage.getItem("name"));
    }, [loggedInUserName]);

    const [accountHover, setAccountHover] = useState(false);

    const profileMenuRef = useRef();
    const hamMenuRef = useRef();

    const handleSignout = () => {
        localStorage.removeItem("name");
        localStorage.removeItem("jwt");
        localStorage.removeItem("email");
        setJwtName(null);
        setUserName(null);
        setEmail(null);
        navigate('');
    }

    const welcomeUser = () => {
        return <button 
        onMouseEnter={() => setAccountHover(true)} 
        onMouseLeave={() => setAccountHover(false)}
        onClick={() => handleSignout()}
        className="navProfileFullScr">{accountHover ? "Sign out?" : `Hello, ${ jwtName }!`}</button>
    }

    const loginPrompt = () => {
        function handle() {
            handleRegisterClick();
            setActivePanel('login');
        }

        return <button ref={loginBtnRef} className="navProfileFullScr" onClick={() => handle()}>Log in</button>
    }

    const [profileMenuOpen, setProfileMenuOpen] = useState(false);

    const handleProfileClick = (route) => {
        handleRegisterClick();
        setActivePanel(route);
        setProfileMenuOpen(false);
    }

    const profileMenuOptions = () => {
        return (
            <>
            <li><button onClick={(() => handleProfileClick('register'))}>Register</button></li>
            <li><button onClick={(() => handleProfileClick('login'))}>Login</button></li>
            </>
        )
    }

    const handleHamMenuClick = () => {
        setProfileMenuOpen(false);
        setHamMenuActive(!hamMenuActive);
    }

    const handleProfileMenuClick = () => {
        setHamMenuActive(false);
        setProfileMenuOpen(!profileMenuOpen);
    }

    useEffect(() => {
        const closeMenu = (event) => {
            if (!profileMenuRef?.current?.contains(event.target) && !hamMenuRef?.current?.contains(event.target)){
                setHamMenuActive(false);
                setProfileMenuOpen(false);
            }
        }

        if (hamMenuActive || profileMenuOpen) {
            document.addEventListener("touch", closeMenu);
        }
        return () => document.removeEventListener("touch", closeMenu);
    }, [hamMenuActive, profileMenuOpen])

    return ( 
        <div className="navbar">
            <div className="desktopMobileDropdownWrap">
                <div ref={hamMenuRef} className={hamMenuActive ? "mobileDropdown_visible" : "mobileDropdown" }>
                    <ul>
                        <li><NavLink to="/" onClick={() => setHamMenuActive(false)}>Home</NavLink></li>
                        <li><NavLink to="movieoftheweek" onClick={() => setHamMenuActive(false)}>Movie of the Week</NavLink></li>
                        <li><NavLink to="topmovies" onClick={() => setHamMenuActive(false)}>Top 100</NavLink></li>
                        <li><NavLink to="blog" onClick={() => setHamMenuActive(false)}>Blog</NavLink></li>
                    </ul>
                </div>
                <div ref={profileMenuRef} className={profileMenuOpen ? "profileMenuDropdown_visible" : "profileMenuDropdown"}>
                    <ul>
                        {jwtName && <li><button>Welcome, {jwtName}!</button></li>}
                        {!jwtName && profileMenuOptions()}
                        {jwtName && <li><button onClick={(() => handleSignout())}>Log out</button></li>}
                    </ul>
                </div>
                <div className="logo">
                    <Link target="_blank" to="https://www.imdb.com/search/title/?groups=top_100&sort=user_rating,desc">
                        <img src={IMDbLogo} alt="IMDb Logo" />
                        <p>IMDb Top 100 Movies</p>
                    </Link>
                </div>
                <nav className="navCont">
                    <ul>
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="movieoftheweek">Movie of the Week</NavLink></li>
                        <li><NavLink to="topmovies">Top 100</NavLink></li>
                        <li><NavLink to="blog">Blog</NavLink></li>
                    </ul>
                </nav>
                <div className="profilesCont">
                    {jwtName ? welcomeUser() : loginPrompt()}
                    <button className="profileNavIcon"><FontAwesomeIcon onClick={() => handleProfileMenuClick()} icon={faUser} size="2xl" style={{ color: "#f4c404", }} /></button>
                    <button className="hamMenu"><FontAwesomeIcon onClick={() => handleHamMenuClick()} icon={faBars} size="2xl" style={{ color: "#f4c404", }} /></button>
                </div>
            </div>
        </div>
     );
}
 
export default Navbar2;