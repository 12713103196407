import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import './StarRating.css';

const StarRating = (props) => {

    const {rating, setRating, hover, setHover, readOnly, id} = props;

    const setColor = (currentRating) => {

        if (readOnly) {
            return { color: currentRating <= rating ? "#ffc107" : "#e4e5e9" }
        }
        else return { color: (hover || rating) >= currentRating ? "#ffc107" : "#e4e5e9" }
    }

    return ( 
        <div className="starContainer">
            {[...Array(5)].map((star, index) => {
                const currentRating = index + 1;
                return(
                    <label key={id + index.toString()}>
                        <input 
                            type="radio"
                            name="rating" 
                            value={currentRating}
                            onClick={readOnly ? null : () => setRating(currentRating)}
                        />
                        <FontAwesomeIcon 
                        className="star" 
                        icon={faStar} size="2xl" style={setColor(currentRating)} 
                        onMouseEnter={readOnly ? null : () => setHover(currentRating)}
                        onMouseLeave={readOnly ? null : () => setHover(null)}
                        />
                    </label>
                ) 
            })}
        </div>
     );
}
 
export default StarRating;